import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

const ImageGroup = ({ basicInfoWithImage, small = false, animation = {} }) => {
    // TODO
    let imageGroupClass = 'image-group'

    if (small) {
        imageGroupClass += ' image-group--sm'
    }

    return (
        <div className={imageGroupClass} {...animation}>
            {/* Image */}
            <GatsbyImage alt={basicInfoWithImage.image.alternativeText} image={getImage(basicInfoWithImage.image.localFile)} />
            {/* Title */}
            <div className="image-group__text">
                {basicInfoWithImage.title && 
                    <h5>{basicInfoWithImage.title}</h5>
                }
                {/* Text */}
                {basicInfoWithImage.description && 
                    <ReactMarkdown
                        children={basicInfoWithImage.description}
                        rehypePlugins={[rehypeRaw]}
                    />
                }
            </div>
        </div>
    )
}

export default ImageGroup