import React, { useState } from "react";
import ImageGroup from "./image-group";

const GroupImageGroup = ({ itemGroup, maxShownMobile = 0, offsetMobile = 0, maxShownDesktop = 0, offsetDesktop = 0, buttonText = '', mobileBreakPoint = 768, small = false, gridItems = 4, animation = false, animated = false }) => {
    // Si están a 0, ponemos el largo del array, en caso contrario pasamos a int lo que recibimos
    if (maxShownMobile <= 0) {
        maxShownMobile = itemGroup.length
    } else {
        maxShownMobile = parseInt(maxShownMobile)
    }
    if (offsetMobile <= 0) {
        offsetMobile = maxShownMobile
    } else {
        offsetMobile = parseInt(offsetMobile)
    }

    if (maxShownDesktop <= 0) {
        maxShownDesktop = itemGroup.length
    } else {
        maxShownDesktop = parseInt(maxShownDesktop)
    }
    if (offsetDesktop <= 0) {
        offsetDesktop = maxShownDesktop
    } else {
        offsetDesktop = parseInt(offsetDesktop)
    }

    // Pasamos a integer
    mobileBreakPoint = parseInt(mobileBreakPoint)

    const win = (typeof window !== "undefined" ? window : null)
    // Validar si estamos en móvil o no
    const inMobile = () => {
        if (win?.innerWidth > mobileBreakPoint) {
            return false
        }
        return true
    }
    // Cálculo para obtener los elementos iniciales que hay que mostrar, se usa en el render y en la redimensión
    const calculateInitialPartnersDescShowMobile = () => {
        return inMobile()
            ? itemGroup.slice(0, offsetMobile)
            : itemGroup.slice(0, offsetDesktop)
    }
    const [partnersDescShow, setPartnersDescShow] = useState(calculateInitialPartnersDescShowMobile())
    // Al modificar el tamaño de la ventana, miramos si hay que ocultar o mostrar elementos
    const desktopMediaQuery = win?.matchMedia(`(min-width: ${mobileBreakPoint + 1}px)`)
    React.useEffect(() => {
        desktopMediaQuery.addEventListener("change", event => {
            if (event.matches) {
                setPartnersDescShow(calculateInitialPartnersDescShowMobile())
            }
        })
    }, [])
    const phoneMediaQuery = win?.matchMedia(`(max-width: ${mobileBreakPoint}px)`)
    React.useEffect(() => {
        phoneMediaQuery.addEventListener("change", event => {
            if (event.matches) {
                setPartnersDescShow(calculateInitialPartnersDescShowMobile())
            }
        })
    }, [])

    // Mostramos más elementos
    const showMorePartnerDesc = () => {
        let max = 0
        if (
            inMobile() && partnersDescShow.length < maxShownMobile
        ) {
            max = partnersDescShow.length + offsetMobile
            if (max > maxShownMobile) {
                max = maxShownMobile
            }
        } else if (
            !inMobile() && partnersDescShow.length < maxShownDesktop
        ) {
            max = partnersDescShow.length + offsetDesktop
            if (max > maxShownDesktop) {
                max = maxShownDesktop
            }
        }
        setPartnersDescShow(itemGroup.slice(0, max))
    }

    return (
        <>
            <div className={`grid-lg-${gridItems}`}>
                {
                    partnersDescShow.map((item, index) => {
                        const animation = animated ? { "data-aos": "zoom-in", "data-aos-delay": 200 * index } : {}
                        return (
                            <ImageGroup key={index} basicInfoWithImage={item} imageSize="lg" small={small} animation={animation} />
                        )
                    })
                }
            </div>
            {/* Si quedan elementos por mostrar, ponemos el botón */}
            {buttonText !== '' &&
                ((inMobile() && partnersDescShow.length < maxShownMobile) ||
                    (!inMobile() && partnersDescShow.length < maxShownDesktop)) &&
                (
                    <button className="button button--lg button--secondary" onClick={() => showMorePartnerDesc()}>
                        {buttonText}
                    </button>
                )}
        </>
    )
}

export default GroupImageGroup