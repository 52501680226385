import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

const ListHighlight = ({ items }) => {
    return (
        <>
            {items.map((item, index) => {
                return (
                    <div className="list-highlight" key={index}>
                        <div className="list-highlight__number">{item.order}</div>
                        <div className="list-highlight__info">
                            <h4>{item.title}</h4>
                            <div className="list-highlight__info__text">
                                <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} />
                            </div>
                        </div>
                    </div>
                )
            })}
        </>
    )
}

export default ListHighlight